import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import "../components/brand.css"

var brand_descriptions = {
  fitnord: [
    "FitNord är en Finsk tillverkare av fitness- och träningsutrustning. FitNord är känt för att erbjuda ett sortiment med bra kvalitet, stilren design och dessutom till ett riktigt bra pris. Du hittar produkter för både hemmagymet och för professionell användning. Även om FitNord har sitt ursprung inom fitnessbranchen så utveklar de ständigt sitt sortiment och breddar sig även inom rehabprodukter samt produkter för att förhindra stillasittande kontorsmiljöer. ",
    "FitNord är en Finsk tillverkare av fitness- och träningsutrustning. FitNord är känt för att erbjuda ett sortiment med bra kvalitet, stilren design till ett bra pris.",
  ],
  insportline: [
    "inSPORTline är en tillverkare från Tjeckien som har mängder med produkter unom hälsa och fitness. De är Tjeckiens största tillverkar inom fitness och de är även en av de största här i Norden. Deras produktkatalog innehåller framförallt elektriska och manuella maskiner, multi-gym och mindre träningsprodukter för alla åldrar. Deras produkter passar så väl i hemmagymmet som på det professionella gymmet.",
    "inSPORTline erbjuder elektriska och manuella maskiner, multi-gym och mindre träningsprodukter för alla åldrar. Deras produkter passar så väl hemmat som i gymmet.",
  ],
  abilica: [
    "Abilicia (uttalas abilika) är ett Norskt fitness-varumärke som grundades 2002. Namnet Abilicia härstammar från det engelska ordet ability som betyder förmågan att utföra. Abilicias målsättning är att inspirerara till ett mer aktivt liv och med hjälp av deras träningsprodukter vill de underlätta hemmaträning så fort man får lite trid över. Det ska finnas produkter för alla faser i livet och för alla olika typer av personer.",
    "Abilicias målsättning är att inspirerara till ett mer aktivt liv och med hjälp av deras träningsprodukter vill de underlätta hemmaträning så fort man får lite trid över.",
  ],
  "master fitness": [
    "Master Fitness är ett gammalt varumärke som funnits ända sedan 1989. De erbjuder träningsredskap för alltifrån styrka och kondition till rehab. Med hög kvalitet och hög standard vill de kunna erbjuda kunderna produkter som passar både i hemmet och i gymmet. Deras mål är att skapa produkter som är funktionella, snygga och inspirerande och som passar dina individuella krav till ett bra pris.",
    "Master Fitness mål är att skapa produkter som är funktionella, snygga och inspirerande och som passar dina individuella krav till ett bra pris.",
  ],
  "nordic fighter": [
    "Nordic Fighter är ett svenskt företag som importerar och säljer produkter inom fitness, styrketräning och kampsport. De väljer sina leverantörer någgrant och undviker mellanhänder för att kunna erbjuda produkter med hög kvalitet till ett bra pris. Deras mål är att pressa priser samtidigt som de erbjuder hög service och kvalité.",
    "Nordic Fighter är ett svenskt företag som importerar och säljer produkter inom fitness, styrketräning och kampsport. Deras mål är att pressa priser samtidigt som de erbjuder hög service och kvalité.",
  ],
  tunturi: [
    "Tunturi är en finsk cykel- och fitnessredskapsvillerkare. Framförallt har de sitt ursprung inom cykelitllverkning men har sedan grundandet 1922 mer och mer gått över till träningsredskap. Med sitt skandinaviska arv vill de tillverkar produkter med stilren design som ska förenkla för dig och din träning. ",
    "Tunturi är ett finskt bolag som har över 100 års erfarenhet inom cykeltillverkning och tärningsredskap.",
  ],
  wahlanders: [
    "Wahlanders är en svensk tillverkare av framförallt bälten. Deras styrketräningsbälten är i regel av läder och är handgjorda. De är IPF-godkända både nationellt och internationellt ",
    "Wahlanders tillverkar handgjorda styrketräningsbälten av läder som är IPF godkända. ",
  ],
}

export default function Bike({ data, location, pageContext }) {
  var nice_brand = data.allMongodbStoreProducts.edges[0].node.brand
  var description = ""
  var short_desc = ""
  var low_key_brand = nice_brand.toLowerCase()
  if (low_key_brand in brand_descriptions) {
    description = brand_descriptions[low_key_brand][0]
    short_desc = brand_descriptions[low_key_brand][1]
  }

  var all_categories = []
  data.allMongodbStoreProducts.edges.forEach(node => {
    node.node.categories.forEach(category => {
      if (!all_categories.includes(category)) {
        all_categories.push(category)
      }
    })
  })
  all_categories.sort()

  return (
    <Layout
      title={nice_brand}
      header={nice_brand}
      location={location}
      pageContext={pageContext}
      addCrumbs={true}
      description={short_desc}
    >
      <h1>{nice_brand}</h1>
      {description}
      <div id="brandWrapper">
        {all_categories.map(function (category, index) {
          return (
            <div>
              <h2>{category}</h2>
              <ul>
                {data.allMongodbStoreProducts.edges.map(function (
                  { node },
                  index
                ) {
                  var element = ""
                  if (node.categories.includes(category)) {
                    element = (
                      <li key={index}>
                        <Link
                          to={"/produkter/" + node.fields.full_name_url + "/"}
                        >
                          {node.full_name}
                        </Link>
                      </li>
                    )
                  }
                  return element
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($brand_url: String!) {
    allMongodbStoreProducts(
      filter: { fields: { brand_url: { eq: $brand_url } } }
      sort: { fields: full_name }
    ) {
      edges {
        node {
          id
          full_name
          brand
          categories
          fields {
            brand_url
            full_name_url
          }
        }
      }
    }
  }
`
